import React, { useEffect } from 'react';
import { Row, Col, Input, Button, Form, Drawer } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddFood = ({ foodAddModal, width = '40%', editFood, setFoodAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();

	useEffect(() => {
		if (editFood) {
			form.setFieldsValue({
				name: editFood?.name || '',
			});
		} else {
			form?.resetFields();
		}
	}, [editFood, form]);

	useEffect(() => {
		if (!foodAddModal) {
			form?.resetFields();
		}
	}, [foodAddModal, form]);

	const handleSubmit = (values) => {
		let data = {
			name: values?.name || '',
			branchId,
		};

		if (!editFood) {
			dispatch(postApi(data, 'ADD_FOOD'));
		} else {
			let url = `${SERVER_IP}api/food/${editFood.id}`;
			dispatch(putApi(data, 'EDIT_FOOD', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_FOOD === 'SUCCESS' || globalRedux.apiStatus.EDIT_FOOD === 'SUCCESS') {
			dispatch(resetApiStatus(editFood ? 'EDIT_FOOD' : 'ADD_FOOD'));
			refreshList?.();
			globalRedux.apiStatus.EDIT_FOOD === 'SUCCESS' && handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editFood, setFoodAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_FOOD === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_FOOD === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editFood ? 'Edit' : 'New'} Food`}
			width={width || '40%'}
			visible={foodAddModal}
			closable
			onClose={() => setFoodAddModal(false)}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-food"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item
								label="Name"
								name="name"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Name" />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setFoodAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editFood ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddFood;
