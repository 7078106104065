import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Button, Form, Drawer, Input, Radio, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { API_STATUS, DATE_FORMAT, GYM_PACKAGES, PAYMENT_METHOD } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import DatePicker from 'components/date-picker';

const PayNowModal = ({ payNowAddModal, width = '40%', editGym, setPayNowAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const amountValue = Form.useWatch('amount', form);
	const [originalAmount, setOriginalAmount] = useState(0);

	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();


	useEffect(() => {
		let balance = originalAmount - amountValue;
		form.setFieldsValue({
			balance,
		});
	}, [amountValue, form, originalAmount]);


	useEffect(() => {
		if (editGym) {
			form.setFieldsValue({
				...editGym,
				amount: editGym.balance,
				balance: 0,
				remaining: editGym.balance,
				paidOn: moment(editGym?.paidOn),
				nextBillDate: moment(editGym?.nextBillDate),
			});
			setOriginalAmount(editGym.balance);
		} else {
			form.setFieldsValue({
				paymentMethod: PAYMENT_METHOD[0]?.value,
				package: GYM_PACKAGES[0]?.value,
				paidOn: moment(new Date()),
				remaining: 0,
				balance: 0,
				amount: GYM_PACKAGES[0]?.price,
				nextBillDate: moment(new Date()).add(GYM_PACKAGES[0]?.days, 'D'),
			});
		}
	}, [editGym, form]);

	const handleSubmit = (values) => {
		let data = {
			...values,
			...editGym,
			gymId: editGym.id,
			groupId: editGym.groupId,
			amount: values?.amount || '',
			balance: values?.balance || 0,
			nextBillDate: values?.nextBillDate || '',
			nextReminderDate: values?.nextReminderDate || '',
			paidOn: values?.paidOn || '',
			paymentMethod: values?.paymentMethod || '',
			remarks: values?.remarks || '',
			package: values?.package,
			paymentFor: 'package',
			...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),
			branchId,
		};
		delete data.id;
		dispatch(postApi(data, 'ADD_GYM_PAYMENT'));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_GYM_PAYMENT === 'SUCCESS' || globalRedux.apiStatus.EDIT_GYM === 'SUCCESS') {
			dispatch(resetApiStatus('ADD_GYM_PAYMENT'));
			// dispatch(resetApiStatus(editGym ? 'EDIT_GYM' : 'ADD_GYM_PAYMENT'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editGym, setPayNowAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_GYM_PAYMENT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_GYM === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`Pay Bill`}
			width={width || '40%'}
			open={payNowAddModal}
			closable
			onClose={() => setPayNowAddModal(false)}
			destroyOnClose>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="pay-now"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							{...layer1FormCol}>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Plan"
								name="package">
								<Select placeholder="Plan" disabled>
									{GYM_PACKAGES?.map((plan, index) => (
										<Select.Option key={index} value={plan.value}>
											{plan?.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Remaining Amount"
								name="remaining"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Remaining Amount" disabled style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Amount"
								name="amount"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Amount" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Balance"
								name="balance"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber disabled placeholder="Balance" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Paid on"
								name="paidOn">
									<DatePicker disabled style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Next Bill Date"
								name="nextBillDate">
									<DatePicker disabled style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Next Reminder Date"
								name="nextReminderDate">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								label="Payment Method"
								name="paymentMethod"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Radio.Group style={{ width: '100%' }} options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
							</Form.Item>
							{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
								<Form.Item
									label="UPI TransactionId"
									name="upiTransactionId"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber placeholder="UPI TransactionId" style={{ width: '100%' }} />
								</Form.Item>
							)}
							<Form.Item
								label="Remarks"
								name="remarks"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setPayNowAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										Pay
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default PayNowModal;
