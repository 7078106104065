// import React, { useState, useEffect } from 'react';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs'; // For converting HTML to Draft content
// import { EditorState, ContentState, convertToRaw } from 'draft-js';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Required CSS
// import './RichTextEditor.css'; // Import custom styles

// const RichTextEditor = ({ value, onChange }) => {
//   console.log('🚀 ~ RichTextEditor ~ value:', value);

//   // Initialize editorState only once with value
//   const [editorState, setEditorState] = useState(() => {
//     if (value) {
//       const blocksFromHtml = htmlToDraft(value);
//       const contentState = ContentState.createFromBlockArray(
//         blocksFromHtml.contentBlocks,
//         blocksFromHtml.entityMap
//       );
//       return EditorState.createWithContent(contentState);
//     }
//     return EditorState.createEmpty();
//   });

//   // Prevent subsequent updates from props
//   const isInitialMount = React.useRef(true);

//   useEffect(() => {
//     if (isInitialMount.current && value) {
//       const blocksFromHtml = htmlToDraft(value);
//       const contentState = ContentState.createFromBlockArray(
//         blocksFromHtml.contentBlocks,
//         blocksFromHtml.entityMap
//       );
//       setEditorState(EditorState.createWithContent(contentState));
//       isInitialMount.current = false; // Mark initial mount as complete
//     }
//   }, [value]);

//   // Handle editor changes
//   const onEditorChange = (state) => {
//     setEditorState(state);
//     const contentState = convertToRaw(state.getCurrentContent());
//     const markup = draftToHtml(contentState);
//     onChange && onChange(markup); // Send the HTML content back to the parent
//   };

//   // Image upload handler
//   const handleImageUpload = (file) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();

//       reader.onloadend = () => {
//         // For base64 image handling:
//         const contentState = editorState.getCurrentContent();
//         const contentWithImage = contentState.createEntity('IMAGE', 'IMMUTABLE', {
//           src: reader.result, // Image as base64
//         });
//         // const entityKey = contentWithImage.getLastCreatedEntityKey();
//         const newEditorState = EditorState.set(editorState, {
//           currentContent: contentWithImage,
//         });
//         setEditorState(newEditorState);

//         resolve({ data: { link: reader.result } });  // Return the base64 URL
//       };

//       reader.onerror = (error) => reject(error);
//       reader.readAsDataURL(file); // Read the file as base64
//     });
//   };

//   // function uploadImageCallBack(file) {
//   //   return new Promise(
//   //     (resolve, reject) => {
//   //       const xhr = new XMLHttpRequest();
//   //       xhr.open('POST', 'https://api.imgur.com/3/image');
//   //       xhr.setRequestHeader('Authorization', 'Client-ID 991a0e15ea25453');
//   //       const data = new FormData();
//   //       data.append('image', file);
//   //       xhr.send(data);
//   //       xhr.addEventListener('load', () => {
//   //         const response = JSON.parse(xhr.responseText);
//   //         resolve(response);
//   //       });
//   //       xhr.addEventListener('error', () => {
//   //         const error = JSON.parse(xhr.responseText);
//   //         reject(error);
//   //       });
//   //     }
//   //   );
//   // }

//   // const toolbarOptions = {
//   //   options: ['inline', 'blockType', 'emoji', 'list', 'textAlign', 'history', 'image'],
//   //   image: {
//   //     uploadCallback: handleImageUpload, // Image upload handler
//   //     previewImage: true, // Optionally, enable image preview
//   //   },
//   // };

//   return (
//     <div className="editor-container">
//       <Editor
//         editorState={editorState}
//         onEditorStateChange={onEditorChange}
//         toolbar={{
//           // options: ['inline', 'blockType', 'emoji', 'list', 'textAlign', 'history', 'image'],
//           inline: { inDropdown: true },
//           list: { inDropdown: true },
//           textAlign: { inDropdown: true },
//           link: { inDropdown: true },
//           history: { inDropdown: true },
//           image: {
//             uploadCallback: handleImageUpload,
//             previewImage: true,
//             alt: { present: true, mandatory: true },
//           },
//         }}
//         wrapperClassName="editor-wrapper"
//         editorClassName="editor"
//       />
//     </div>
//   );
// };

// export default RichTextEditor;
import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.css';

const RichTextEditor = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState(() => {
    if (value) {
      try {
        const blocksFromHtml = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        return EditorState.createWithContent(contentState);
      } catch (error) {
        console.error('Error initializing editor state:', error);
        return EditorState.createEmpty();
      }
    }
    return EditorState.createEmpty();
  });

  const isInitialMount = React.useRef(true);

  useEffect(() => {
    if (isInitialMount.current && value) {
      try {
        const blocksFromHtml = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
        isInitialMount.current = false;
      } catch (error) {
        console.error('Error updating editor state:', error);
      }
    }
  }, [value]);

  const onEditorChange = (state) => {
    setEditorState(state);
    const contentState = convertToRaw(state.getCurrentContent());
    const markup = draftToHtml(contentState);
    onChange && onChange(markup);
  };

  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        try {
          const contentState = editorState.getCurrentContent();
          // const contentWithImage = contentState.createEntity('IMAGE', 'IMMUTABLE', {
          //   src: reader.result,
          // });
          const newEditorState = EditorState.push(
            editorState,
            contentState,
            'insert-fragment'
          );
          setEditorState(newEditorState);

          resolve({ data: { link: reader.result } });
        } catch (error) {
          console.error('Error handling image upload:', error);
          reject(error);
        }
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handlePastedText = (text, html, editorState, onChange) => {
    try {
      // Handle screenshots (data:image URLs)
      if (text.startsWith('data:image')) {
        const file = dataURLtoFile(text, 'pasted-image.png');
        handleImageUpload(file).then(() => {
          const newEditorState = EditorState.push(
            editorState,
            ContentState.createFromText(''),
            'insert-fragment'
          );
          setEditorState(newEditorState);
        });
        return true; // Prevent default paste behavior
      }

      // Handle HTML content with embedded images
      if (html) {
        // Extract image URLs from the HTML
        const imageRegex = /<img[^>]+src="([^">]+)"/g;
        const images = [];
        let match;
        while ((match = imageRegex.exec(html))) {
          images.push(match[1]); // Collect image URLs
        }

        // Remove <img> tags from the HTML
        const cleanedHtml = html.replace(/<img[^>]*>/g, '');

        // Convert the cleaned HTML to Draft.js content
        const blocksFromHtml = htmlToDraft(cleanedHtml);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        let newEditorState = EditorState.push(
          editorState,
          contentState,
          'insert-fragment'
        );

        // Upload and insert images
        if (images.length > 0) {
          images.forEach((imageSrc) => {
            if (imageSrc.startsWith('data:')) {
              const file = dataURLtoFile(imageSrc, 'pasted-image.png');
              handleImageUpload(file).then(() => {
                const newEditorState = EditorState.push(
                  editorState,
                  ContentState.createFromText(''),
                  'insert-fragment'
                );
                setEditorState(newEditorState);
              });
            }
          });
        }

        setEditorState(newEditorState);
        return true; // Prevent default paste behavior
      }

      // Handle plain text
      return false; // Allow default paste behavior for plain text
    } catch (error) {
      console.error('Error handling pasted content:', error);
      return false; // Fallback to default behavior
    }
  };

  // Helper function to convert data URL to File object
  const dataURLtoFile = (dataURL, filename) => {
    try {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    } catch (error) {
      console.error('Error converting data URL to file:', error);
      return null;
    }
  };

  return (
    <div className="editor-container">
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: handleImageUpload,
            previewImage: true,
            alt: { present: true, mandatory: true },
          },
        }}
        wrapperClassName="editor-wrapper"
        editorClassName="editor"
        handlePastedText={handlePastedText}
      />
    </div>
  );
};

export default RichTextEditor;