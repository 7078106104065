import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Switch, Button } from 'antd';
import moment from 'moment';
import { EditOutlined, UpSquareOutlined, WalletOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { generatePagination } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { getDateFormat, getDifferenceDays, getINRMoneyFormat } from 'services/Utils';
import GymListPresentational from './gym-list-presentational';
import { putApi } from 'redux/sagas/putApiSaga';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const GymListFunctional = React.memo(() => {
	const gymRedux = useSelector((state) => state.gymRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [gymAddModal, setGymAddModal] = useState(false);
	const [payNowAddModal, setPayNowAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(gymRedux.gyms);
	const [editGym, setEditGym] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [ renewGymModal, setRenewGymModal ] = useState(false);
	const [ renewGym, setRenewGym ] = useState(null);

	const getGyms = useCallback(() => {
		let url = `${SERVER_IP}api/users`;
		dispatch(getApi('GET_GYMS', url));
	}, [dispatch]);

	useEffect(() => {
		getGyms();
	}, [getGyms]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_COACH === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_COACH'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getGyms();
		}
	}, [globalRedux.apiStatus.DELETE_COACH, dispatch, getGyms]);

	useEffect(() => {
		setTableData(gymRedux.gyms);
	}, [gymRedux.gyms]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((gym) => {
			return (
				(gym?.gymName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(gym?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(gym?.email || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(gym?.address || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
				// (gym?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditGym(rowData);
		setGymAddModal(true);
	};

	const handleAddGym = () => {
		setEditGym(null);
		setGymAddModal(true);
	};

	const handleActivation = (isAccountActivated, row) => {
		let data = {
			isAccountActivated,
		};
		let url = `${SERVER_IP}api/users/changestatus/${row.id}`;
		dispatch(putApi(data, 'CHANGE_STATUS', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.CHANGE_STATUS === 'SUCCESS') {
			getGyms?.();
		}
	}, [globalRedux.apiStatus?.CHANGE_STATUS, getGyms]);

	const changingStatus = globalRedux.apiStatus.CHANGE_STATUS === API_STATUS.PENDING;

	const handlePay = (rowData) => {
		setEditGym(rowData);
		setPayNowAddModal(true);
	};

	const handleRenew = (rowData) => {
		console.log({rowData})
		setRenewGym(rowData);
		setRenewGymModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'gymNumber',
			key: 'gymNumber',
			fixed: 'left',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Gym Name',
			dataIndex: 'gymName',
			key: 'gymName',
			width: '15%',
			sorter: (a, b) => a?.gymName?.localeCompare(b?.gymName),
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		{
			title: 'Owner Name',
			dataIndex: 'firstName',
			key: 'firstName',
			width: '15%',
			sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
			fixed: 'left',
			render: (value, row) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={`${value} ${row?.lastName}`} />
				</div>
			),
		},
		{
			title: 'Mobile',
			dataIndex: 'mobile',
			ellipsis: true,
			width: '10%',
			sorter: (a, b) => a?.mobile - b?.mobile,
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			ellipsis: true,
			sorter: (a, b) => a?.email?.localeCompare(b?.email),
			align: 'left',
			width: '8%',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Status',
			dataIndex: 'isAccountActivated',
			align: 'left',
			width: '10%',
			render: (value, row) => (
				<Switch
					style={{ width: '100%' }}
					checkedChildren="Active"
					unCheckedChildren="Inactive"
					checked={value}
					onChange={(value) => handleActivation(value, row)}
				/>
			),
		},
		{
			title: 'Next Billing Date',
			dataIndex: 'nextBillDate',
			align: 'left',
			width: '8%',
			render: (value) => (
				<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
			),
		},
		{
			title: 'Amount Paid',
			dataIndex: 'amount',
			align: 'right',
			width: '8%',
			render: (value) => getINRMoneyFormat(value),
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			align: 'right',
			width: '8%',
			render: (value) => getINRMoneyFormat(value),
		},
		{
			title: 'Created/Joined Date',
			dataIndex: 'created_at',
			align: 'left',
			width: '10%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Action',
			align: 'center',
			width: '13%',
			dataIndex: 'gymName',
			render: (value, row, index) => {
				let buttonDisable = false;
				let nextBillDate = row.nextBillDate;
				console.log( "Days difference : ", getDifferenceDays(new Date(nextBillDate), new Date()), " Balance : ", row?.balance, row)
				if (getDifferenceDays(new Date(nextBillDate), new Date()) > 0) {
					buttonDisable = true;
				}
				if(Number(row?.balance) > 0) {
					buttonDisable = false;
				}
				return (
					<Row justify="start">
						<Col className="edit_icon" title='Edit' onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
						{!buttonDisable && row.balance > 0 && (
							<Col>
								<Button
									className="edit_icon"
									title='Pay Now'
									style={{ backgroundColor: '#3cc13b' }}
									onClick={() => handlePay(row)}
									disabled={buttonDisable}>
									<WalletOutlined />
								</Button>
							</Col>
						)}
						{!buttonDisable && (row.balance === 0 || row.balance == null) && (
							<Col>
								<Button
									className="edit_icon"
									title='Renew'
									style={{ backgroundColor: 'purple' }}
									onClick={() => handleRenew(row)}
									disabled={buttonDisable}>
									<UpSquareOutlined />
								</Button>
							</Col>
						)}
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_GYMS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setGymAddModal(false);
		setEditGym(null);
		setPayNowAddModal(false);
		setRenewGym(null);
		setRenewGymModal(false);
		getGyms();
	}, [getGyms]);

	return (
		<GymListPresentational
			{...{
				filteredData,
				changingStatus,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddGym,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				gymAddModal,
				setGymAddModal,
				refreshList: getGyms,
				editGym,
				handleClose,
				payNowAddModal,
				setPayNowAddModal,
				renewGymModal,
				renewGym,
				setRenewGym,
				setRenewGymModal
			}}
		/>
	);
});

export default GymListFunctional;
