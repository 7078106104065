import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Drawer, Input, Radio, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { API_STATUS, DATE_FORMAT, GYM_PACKAGES, PAYMENT_METHOD } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import DatePicker from 'components/date-picker';
import dayjs from 'dayjs';

const RenewModal = ({  width = '40%', refreshList, handleClose, 
	renewGymModal,
	renewGym,
	setRenewGymModal,
	setRenewGym }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const amountValue = Form.useWatch('amount', form);
	const [originalAmount, setOriginalAmount] = useState(0);
	const packageValue = Form.useWatch('package', form);

	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();
	console.log({renewGym, renewGymModal})

	useEffect(() => {
		let balance = originalAmount - amountValue;
		form.setFieldsValue({
			balance,
		});
	}, [amountValue, form, originalAmount]);

	useEffect(() => {
		if (!!packageValue) {
			const packageInd = GYM_PACKAGES.findIndex((item) => item.value === packageValue);
			const gymPackage = GYM_PACKAGES[packageInd];
			const paidOn = dayjs();
			const nextBillDate = dayjs(paidOn).add(gymPackage?.days, 'days');
			setOriginalAmount(GYM_PACKAGES[packageInd]?.price)
			form.setFieldsValue({
				amount: GYM_PACKAGES[packageInd]?.price,
				total: GYM_PACKAGES[packageInd]?.price,
				balance: 0,
				package: GYM_PACKAGES[packageInd]?.value,
				paidOn,
				nextBillDate: nextBillDate,
				nextReminderDate: nextBillDate,
			});
		}
	}, [packageValue, form]);

	useEffect(() => {
		if (renewGym) {
			form.setFieldsValue({
				...renewGym,
				amount: renewGym.balance,
				balance: 0,
				remaining: renewGym.balance,
				total: renewGym.balance,
				paidOn: renewGym?.paidOn ? dayjs(renewGym?.paidOn) : null,
				nextBillDate: renewGym?.nextBillDate ? dayjs(renewGym?.nextBillDate) : null,
			});
			setOriginalAmount(renewGym.balance);
		} else {
			form.resetFields();
		}
	}, [renewGym, form]);

	const handleSubmit = (values) => {
		let data = {
			...values,
			...renewGym,
			gymId: renewGym.id,
			groupId: renewGym.groupId,
			amount: values?.amount || '',
			balance: values?.balance || 0,
			nextBillDate: values?.nextBillDate || '',
			nextReminderDate: values?.nextReminderDate || '',
			paidOn: values?.paidOn || '',
			paymentMethod: values?.paymentMethod || '',
			remarks: values?.remarks || '',
			package: values?.package,
			paymentFor: 'package',
			...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),
			branchId,
		};
		delete data.id;
		dispatch(postApi(data, 'ADD_GYM_PAYMENT'));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_GYM_PAYMENT === 'SUCCESS' || globalRedux.apiStatus.EDIT_GYM === 'SUCCESS') {
			dispatch(resetApiStatus('ADD_GYM_PAYMENT'));
			// dispatch(resetApiStatus(renewGym ? 'EDIT_GYM' : 'ADD_GYM_PAYMENT'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, renewGym, setRenewGymModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_GYM_PAYMENT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_GYM === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`Pay Bill`}
			width={width || '40%'}
			open={renewGymModal}
			closable
			onClose={() => setRenewGymModal(false)}
			destroyOnClose>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="pay-now"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							{...layer1FormCol}>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Plan"
								name="package">
								<Select placeholder="Plan">
									{GYM_PACKAGES?.map((plan, index) => (
										<Select.Option key={index} value={plan.value}>
											{plan?.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label="Total Amount" name="total">
								<InputNumber disabled placeholder="Total amount" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item label="Paid Amount" name="amount">
								<InputNumber placeholder="Enter the amount paid" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item label="Balance" name="balance">
								<InputNumber disabled placeholder="Balance amount" style={{ width: '100%' }} />
							</Form.Item>
							{/* <Form.Item
								label="Amount"
								name="amount"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Amount" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Balance"
								name="balance"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Balance" disabled style={{ width: '100%' }} />
							</Form.Item> */}
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Paid on"
								name="paidOn">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Next Bill Date"
								name="nextBillDate">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Next Reminder Date"
								name="nextReminderDate">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								label="Payment Method"
								name="paymentMethod"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Radio.Group style={{ width: '100%' }} options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
							</Form.Item>
							{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
								<Form.Item
									label="UPI TransactionId"
									name="upiTransactionId"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber placeholder="UPI TransactionId" style={{ width: '100%' }} />
								</Form.Item>
							)}
							<Form.Item
								label="Remarks"
								name="remarks"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setRenewGymModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										Renew
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default RenewModal;
