import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { FacebookOutlined, EditFilled, InstagramOutlined, MailOutlined, MobileOutlined, YoutubeOutlined } from '@ant-design/icons';
import { SERVER_IP } from 'assets/Config';
import FileUpload from 'components/file-upload/file-upload';
import { useDispatch, useSelector } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

// const beforeUpload = (file) => {
// 	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
// 	if (!isJpgOrPng) {
// 		message.error('You can only upload JPG/PNG file!');
// 	}
// 	const isLt2M = file.size / 1024 / 1024 < 2;
// 	if (!isLt2M) {
// 		message.error('Image must smaller than 2MB!');
// 	}
// 	return isJpgOrPng && isLt2M;
// };

export const IMAGE_URL = SERVER_IP + '';

const ProfilePresentational = ({ userDetails, fileList, onChange, onPreview }) => {
	const [loading] = React.useState(false);
	const [isEditSocial, setIsEditSocial] = React.useState(false);
	// const [, setBannerLoading] = React.useState(false);
	// const [imageUrl, setImageUrl] = React.useState();
	// const [bannerImageUrl, setBannerImageUrl] = React.useState();
	// const IMAGE_ACTION_URL = SERVER_IP + 'api/media/upload';
	const loginRedux = useSelector((state) => state.loginRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [profilePicList, setProfilePicList] = React.useState([]);
	const [bannerList, setBannerList] = React.useState([]);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	// const handleChange = (info, type = 'profile') => {
	// 	if (info.file.status === 'uploading') {
	// 		type === 'profile' ? setLoading(true) : setBannerLoading(true);
	// 		return;
	// 	}
	// 	if (info.file.status === 'done') {
	// 		// Get this url from response in real world.
	// 		getBase64(info.file.originFileObj, (url) => {
	// 			if (type === 'profile') {
	// 				setLoading(false);
	// 				setImageUrl(url);
	// 				// dispatch(postApi(data, 'PROFILE_IMAGE_UPDATE'));
	// 			} else {
	// 				setBannerLoading(false);
	// 				setBannerImageUrl(url);
	// 			}
	// 		});
	// 	}
	// };
	// const uploadButton = (
	// 	<div>
	// 		{loading ? <LoadingOutlined /> : <UploadOutlined />}
	// 		<div style={{ marginTop: 8 }}>Upload</div>
	// 	</div>
	// );

	const updateProfilePic = async () => {
		if (loginRedux.profilePic !== profilePicList[0].url) {
			const data = {
				profilePic: profilePicList[0].thumbUrl,
			};
			let url = `${SERVER_IP}api/users/${loginRedux.id}`;
			dispatch(putApi(data, 'EDIT_CUSTOMER', url));
		}
	};

	useEffect(() => {
		const updatebannerPic = async () => {
			const data = {
				bannerImage: bannerList[0].thumbUrl,
			};
			let url = `${SERVER_IP}api/users/${loginRedux.id}`;
			dispatch(putApi(data, 'EDIT_CUSTOMER', url));
		};
		bannerList.length > 0 && updatebannerPic();
	}, [bannerList, dispatch, loginRedux]);

	useEffect(() => {
		if (userDetails?.profilePic) {
			setProfilePicList([
				{
					uid: userDetails?.profilePic,
					name: userDetails?.firstName + ' ' + userDetails?.lastName,
					url: SERVER_IP + userDetails?.profilePic,
					thumbUrl: userDetails?.thumbUrl,
				},
			]);
		}
		if (userDetails?.bannerImage) {
			setBannerList([
				{
					uid: userDetails?.bannerImage,
					name: userDetails?.firstName + ' ' + userDetails?.lastName,
					url: SERVER_IP + userDetails?.bannerImage,
					thumbUrl: userDetails?.thumbUrl,
				},
			]);
		}
	}, [userDetails]);
	console.log('🚀 ~ ProfilePresentational ~ userDetails:', bannerList);

	const onSocialEditClick = () => {
		setIsEditSocial(!isEditSocial)
	}

	const onUpdateSocialLinks = (values) => {
		const sData = {
			socialLinks: values,
		};
		let url = `${SERVER_IP}api/users/${loginRedux.id}`;
		dispatch(putApi(sData, 'EDIT_USER', url));
	}

	useEffect(() => {
		if (globalRedux.apiStatus.EDIT_USER === 'SUCCESS') {
			dispatch(resetApiStatus('EDIT_PERIOD'));
			form?.resetFields();
			setIsEditSocial(false);
		}
	}, [dispatch, form, globalRedux.apiStatus.EDIT_USER]);

	const onEditCancel = () => {
		form.resetFields();
		setIsEditSocial(false);
	};

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	return (
		<Row className="profileContainer">
			<Col span={24}>
				<Row>
					<Col
						span={24}
						className="banner"
						style={{
							...((bannerList[0]?.url || bannerList[0]?.thumbUrl) && {
								backgroundImage: bannerList[0]?.thumbUrl ? `url(${bannerList[0]?.thumbUrl})` : `url(${bannerList[0]?.url})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
							}),
						}}>
						<div className="banner-upload">
							<FileUpload
								aspect={16 / 9}
								className="avatar-uploader"
								listType="picture-card"
								setFileList={setBannerList}
								action={'/'}
								fileList={bannerList}
								max={1}
								afterUpload={() => {}}
							/>
							{/* <ImgCrop showGrid rotationSlider aspectSlider showReset>
								<Upload
									listType="picture-card"
									className="avatar-uploader"
									name="picture-card"
									showUploadList={false}
									action={IMAGE_ACTION_URL}
									onChange={(e) => handleChange(e, 'banner')}>
									{bannerImageUrl ? <img src={bannerImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
								</Upload>
							</ImgCrop> */}
						</div>
						<Col className="profileIcon">
							<FileUpload
								className="avatar-uploader"
								listType="picture-circle"
								afterUpload={() => {}}
								action={'/'}
								setFileList={setProfilePicList}
								fileList={profilePicList}
								max={1}
							/>
						</Col>
					</Col>
					<Col span={24} className="content">
						<Col className="details">
							<Col className="gymName">{userDetails?.gymName}</Col>
							<Col className="ownerName">
								{userDetails?.firstName} {userDetails?.lastName}
							</Col>
							<Col className="description">{userDetails?.description}</Col>
							<Col className="contact">
								<Col className="email">
									<MailOutlined /> {userDetails?.email}
								</Col>
								<Col className="mobile">
									<MobileOutlined /> {userDetails?.mobile}
								</Col>
							</Col>
							<Col className="socialLinks">
								<Row gutter={[10, 10]}>
									<Col className="link">
										<Row align="middle">
											<Col style={{ paddingRight: 5 }}>
												<FacebookOutlined />
											</Col>
											<Col>
												<a href={userDetails?.socialLinks?.facebook} target='_blank' rel="noreferrer" >Facebook</a>
											</Col>
										</Row>
									</Col>
									<Col className="link">
										<Row align="middle">
											<Col style={{ paddingRight: 5 }}>
												<InstagramOutlined />
											</Col>
											<Col>
												<a href={userDetails?.socialLinks?.instagram} target='_blank' rel="noreferrer" >Instagram</a>
											</Col>
										</Row>
									</Col>
									<Col className="link">
										<Row align="middle">
											<Col style={{ paddingRight: 5 }}>
												<YoutubeOutlined />
											</Col>
											<Col>
												<a href={userDetails?.socialLinks?.youtube} target='_blank' rel="noreferrer">YouTube</a>
											</Col>
										</Row>
									</Col>
									<Col className="link">
										<Row align="middle">
											<Col style={{ paddingRight: 5 }} onClick={onSocialEditClick}>
												<EditFilled />
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Col>
						{ isEditSocial &&
								<Col md={8} style={{ padding: 5 }} >
									<Row style={{ marginTop: 20 }}>
										<Form
											name="add-period"
											className="required_in_right"
											style={{ width: '100%' }}
											colon={true}
											labelAlign="left"
											form={form}
											onFinish={onUpdateSocialLinks}
											initialValues={{}}
											{...layer1FormCol}>
											<Form.Item
												label="Facebook"
												name="facebook"
												initialValue={userDetails?.socialLinks?.facebook}
												rules={[]}>
												<Input placeholder="Facebook URL" />
											</Form.Item>
											<Form.Item
												label="Instagram"
												name="instagram"
												initialValue={userDetails?.socialLinks?.instagram}
												rules={[]}>
												<Input placeholder="Instagram URL" />
											</Form.Item>
											<Form.Item
												label="YouTube"
												name="youtube"
												initialValue={userDetails?.socialLinks?.youtube}
												rules={[]}>
												<Input placeholder="YouTube URL" />
											</Form.Item>
											<Form.Item
												wrapperCol={{
													offset: 0,
													span: 24,
												}}>
												<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
													<Button danger style={{ width: '49%' }} onClick={() => onEditCancel(false)}>
														Cancel
													</Button>
													<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
														Update
													</Button>
												</Row>
											</Form.Item>
										</Form>
									</Row>
								</Col>
							}
						<Col style={{ padding: 20 }}>
							<Button type="primary" onClick={updateProfilePic}>
								Save changes
							</Button>
						</Col>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ProfilePresentational;
