import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Row, Col, Button, Form, Drawer, Select, Divider, Input, Radio } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector, useDispatch } from 'react-redux';
import { API_STATUS, DIET_PLAN_CATEGORY_LIST } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { formQueryStringFromObject } from 'helpers';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { putApi } from 'redux/sagas/putApiSaga';
import TimePicker from 'components/time-picker';
import FoodModal from 'components/food-modal';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import dayjs from 'dayjs';
import PageLoader from 'components/page-loader';

const layer1FormCol = {
	labelCol: { span: 5 },
	wrapperCol: { span: 8 },
};


const options = [
	{
		label: 'Copy From Template',
		value: 'template',
	},
	{
		label: 'Create New',
		value: 'new',
	},
];

const defaultDietItem = () => ({
	time: null,
	foodId: '',
	qty: '',
});

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const createWeekDaysTemplate = () => {
	const weekDays = Array.from({ length: 7 }, (_, j) => {
		return {
			name: days[j],
			dietPlans: Array.from({ length: 4 }, () => defaultDietItem()), // Create 4 default diet items
		};
	});
	return weekDays;
};

const createDefaultDietPlan = (weekCount = 1) => {
	const weeks = Array.from({ length: weekCount }, (_, i) => {
		return {
			name: `Week${i + 1}`,
			days: createWeekDaysTemplate(),
		};
	});
	return {
		weeks,
	};
};

const AddDietPlan = ({ dietPlanAddModal, width = '70%', editDietPlan, setDietPlanAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const customers = useSelector((state) => state?.customerRedux?.customers || []);
	const templates = useSelector((state) => state?.dietPlanTemplateRedux?.dietPlanTemplate || []);
	const editDietPlanRedux = useSelector((state) => state?.dietPlanRedux?.editDietPlan || {});
	const buttonRef = useRef();
	const [ type, setType ] = useState('template');

	const [dietPlans, setDietPlans] = useState([]);
	// console.log('🚀 ~ AddDietPlan ~ dietPlans:', dietPlans);

	const [, setHistory] = useState([dietPlans]);

	useHotkeys('ctrl + n', () => buttonRef?.current?.click());
	useHotkeys('ctrl + z', () => undoLastChange());

	const undoLastChange = () => {
		// setHistory((prevHistory) => {
		// 	if (prevHistory.length > 1) {
		// 		const newHistory = prevHistory.slice(0, -1);
		// 		setDietPlans(newHistory[newHistory.length - 1]);
		// 		return newHistory;
		// 	}
		// 	return prevHistory;
		// });
	};

	useEffect(() => {
		if (editDietPlan?.id) {
			dispatch(getApi('GET_DIET_PLAN_BY_ID', `${SERVER_IP}api/dietPlan/${editDietPlan?.id}`));
		} else {
			form?.resetFields();
			setDietPlans([]);
			setHistory([]);
		}
	}, [dispatch, editDietPlan, form]);

	console.log(
		'🚀 ~ useEffect ~ globalRedux.apiStatus.GET_DIET_PLAN_BY_ID:',
		globalRedux.apiStatus.GET_DIET_PLAN_BY_ID,
		globalRedux.apiStatus.ADD_DIET_PLAN
	);

	// Remove dispatch and form from the dependency array if not needed in the effect
	useEffect(() => {
		if (editDietPlan?.id && globalRedux.apiStatus.GET_DIET_PLAN_BY_ID === 'SUCCESS') {
			dispatch(resetApiStatus('GET_DIET_PLAN_BY_ID'));
			form.resetFields();
			form.setFieldsValue(editDietPlanRedux);
			setDietPlans(editDietPlanRedux);
			setHistory([editDietPlanRedux]);
		}
		// Include only necessary dependencies
	}, [globalRedux.apiStatus.GET_DIET_PLAN_BY_ID, editDietPlanRedux, editDietPlan, dispatch, form]);

	const customerLoading = useMemo(() => globalRedux?.apiStatus.GET_CUSTOMER === API_STATUS.PENDING, [globalRedux.apiStatus]);
	const apiString = useMemo(() => formQueryStringFromObject({ branchId }), [branchId]);

	const fetchCustomers = useCallback(() => {
		if (branchId) {
			dispatch(getApi('GET_CUSTOMERS', `${SERVER_IP}api/customers?${apiString}`));
			dispatch(getApi('GET_DIET_PLAN_TEMPLATE', `${SERVER_IP}api/dietPlanTemplate?${apiString}`));
		}
	}, [dispatch, branchId, apiString]);

	useEffect(() => {
		fetchCustomers();
	}, [fetchCustomers]);

	const addDietItem = (weekInd, dayInd) => {
		const newDiets = JSON.parse(JSON.stringify(dietPlans)); // deep copy to avoid state mutation
		newDiets.weeks[weekInd].days[dayInd].dietPlans.push(defaultDietItem());
		setHistory((prevHistory) => [...prevHistory, newDiets]);
		setDietPlans(newDiets);
	};

	const updateDietItem = (weekInd, dayInd, itemInd, field, value) => {
		setDietPlans((prevPlans) => {
			const week = prevPlans.weeks[weekInd];
			const day = week.days[dayInd];
			const item = day.dietPlans[itemInd];
			item[field] = value;
			day.dietPlans[itemInd] = item;
			week.days[dayInd] = day;
			prevPlans.weeks[weekInd] = week;
			setHistory((prevHistory) => [...prevHistory, prevPlans]);
			return prevPlans;
		});
	};

	const removeDietItem = (weekInd, dayInd, itemInd) => {
		const prevPlans = { ...dietPlans };
		prevPlans.weeks[weekInd].days[dayInd].dietPlans.splice(itemInd, 1);
		const updatedPlans = prevPlans;
		setHistory((prevHistory) => [...prevHistory, updatedPlans]);

		setDietPlans(updatedPlans);
	};

	const addWeek = () => {
		const prevPlans = dietPlans;
		const newWeekNumber = prevPlans.weeks ? prevPlans.weeks.length + 1 : 1;
		const newWeekObj = {
			name: `Week${newWeekNumber}`,
			days: createWeekDaysTemplate(),
		};
		console.log({ newWeekObj });
		if (!prevPlans.weeks || prevPlans.weeks.length <= 0) {
			prevPlans.weeks = [];
		}
		prevPlans.weeks.push(newWeekObj);
		setDietPlans(prevPlans);
		setHistory((prevHistory) => [...prevHistory, prevPlans]);
	};

	const removeWeek = (weekInd) => {
		setDietPlans((prevPlans) => {
			prevPlans.weeks.splice(weekInd, 1);
			const remainingWeeks = prevPlans.weeks;
			console.log({ remainingWeeks, prevPlans });
			setHistory((prevHistory) => [...prevHistory, remainingWeeks]);
			return remainingWeeks;
		});
	};

	const cleanData = (data) => {
		console.log('cleaning data...', data);
		const cleanedData = { ...data };
		cleanedData.weeks.forEach((week) => {
			week.days.forEach((day) => {
				day.dietPlans = day.dietPlans.filter((dietItem) => dietItem.foodId !== '');
			});
		});
		return cleanedData;
	};

	const handleSubmit = (values) => {
		const data = { ...dietPlans, ...values, type, branchId };
		if(type === 'template' && !editDietPlan?.id) {
			data.weeks = [];
		}
		const apiMethod = editDietPlan ? putApi : postApi;
		const apiEndpoint = editDietPlan ? `api/dietPlan/${editDietPlan?.id}` : 'api/dietPlan';
		const body = cleanData(data);
		dispatch(apiMethod(body, editDietPlan ? 'EDIT_DIET_PLAN' : 'ADD_DIET_PLAN', `${SERVER_IP}${apiEndpoint}`));
		onClose();
	};

	useEffect(() => {
		if (globalRedux.apiStatus.EDIT_DIET_PLAN === 'SUCCESS' || globalRedux.apiStatus.ADD_DIET_PLAN === 'SUCCESS') {
			dispatch(resetApiStatus(editDietPlan ? 'EDIT_DIET_PLAN' : 'ADD_DIET_PLAN'));
		}
	}, [globalRedux.apiStatus.EDIT_DIET_PLAN, globalRedux.apiStatus.ADD_DIET_PLAN, editDietPlan, dispatch]);

	const getFoods = useCallback(() => {
		let url = `${SERVER_IP}api/food/?branchId=${globalRedux?.selectedBranch?.id}`;
		dispatch(getApi('GET_FOODS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getFoods();
	}, [getFoods]);

	const onClose = () => {
		setDietPlanAddModal(false);
		form.resetFields();
		dispatch({ type: 'RESET_DIET_PLAN_BY_ID', payload: {} });
		refreshList();
		handleClose();
		setDietPlans([]);
		setHistory([]);
	};
	const getDietPlanByIdLoading = useMemo(() => globalRedux?.apiStatus?.GET_DIET_PLAN_BY_ID === API_STATUS.PENDING, [globalRedux?.apiStatus]);

	const onChangeType = (type) => {
		setType(type.target.value);
		if (type.target.value === 'template') {
			form.setFieldsValue({ templateId: '' });
		} else {
			setDietPlans(createDefaultDietPlan(1));
			setHistory([createDefaultDietPlan(1)]);
		}
	}

	return (
		<Drawer
			placement="right"
			title={`${editDietPlan ? 'Edit' : 'New'} Diet Plan`}
			width={width}
			open={dietPlanAddModal}
			closable
			onClose={onClose}
			destroyOnClose>
			{getDietPlanByIdLoading && <PageLoader />}
			<Row>
				<Col span={24}>
					<Form
						scrollToFirstError
						name="add-dietPlan"
						className="required_in_right"
						style={{ width: '100%' }}
						colon
						labelAlign="left"
						form={form}
						onFinish={handleSubmit}
						initialValues={{}}
						{...layer1FormCol}>
						<Form.Item label="Customer Name" name="customerId" rules={[{ required: true, message: 'This Field is required!' }]}>
							<Select placeholder="Select Customer" loading={customerLoading}>
								{customers.map((customer) => (
									<Select.Option
										key={customer.id}
										value={customer.id}>{`${customer.customerName} - ${customer.mobile}`}</Select.Option>
								))}
							</Select>
						</Form.Item>
						{ !editDietPlan?.id && <>
							<Divider style={{ borderColor: '#000' }}>Diet Plan</Divider>
							<br />
							<Row justify={'center'}>
								<Col span={18} style={{
									textAlign: 'center'
								}}>
									<Radio.Group options={options} onChange={onChangeType} value={type} optionType="button" buttonStyle="solid" />
								</Col>
							</Row>
							<br />
						</> }
						{type === 'template' && !editDietPlan?.id &&
							<>
								<Form.Item label="Template Name" name="templateId" rules={[{ required: true, message: 'This Field is required!' }]}>
									<Select placeholder="Select Template" loading={customerLoading}>
										{templates.map((template) => (
											<Select.Option
												key={template.id}
												value={template.id}>{template.name}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</>}
							{(type === 'new' || editDietPlan?.id) && 
							<>
								<Form.Item label="Category" name="category" rules={[{ required: true, message: 'This Field is required!' }]}>
									<Select placeholder="Select Category">
										{DIET_PLAN_CATEGORY_LIST.map((category) => (
											<Select.Option key={category.id} value={category.id}>
												{category.categoryName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>

								<div style={{ margin: '20px 0' }}>
									<Divider />
								</div>
								<h4>Diet Schedule</h4>
								{dietPlans.weeks &&
									dietPlans.weeks.map((week, weekInd) => (
										<React.Fragment key={`week-${weekInd}`}>
											<Row
												justify="space-between"
												align="middle"
												gutter={8}
												style={{
													border: '1px solid #f0f0f0',
													padding: '8px',
													marginBottom: '10px',
													backgroundColor: '#f0f0f0',
												}}>
												<Col>
													<h4
														style={{
															margin: 0,
														}}>
														{week.name}
													</h4>
												</Col>
												<Col>
													<Button danger icon={<DeleteOutlined />} onClick={() => removeWeek(weekInd)} />
												</Col>
											</Row>
											{week.days.map((day, dayInd) => (
												<Row key={`week-${weekInd}-day-${dayInd}`} style={{ margin: '10px 0' }} gutter={16}>
													<Col span={5}>
														<h5>{day.name}</h5>
													</Col>
													<Col span={19}>
														{day.dietPlans.map((item, itemInd) => (
															<Row
																key={`week-${weekInd}-day-${dayInd}-item-${itemInd}`}
																style={{ marginBottom: '8px' }}
																gutter={8}>
																<Col span={5}>
																	<TimePicker
																		style={{ width: '100%' }}
																		use12Hours
																		format="h A"
																		placeholder="Select Time"
																		onChange={(value) => updateDietItem(weekInd, dayInd, itemInd, 'time', value)}
																		value={item.time ? dayjs(item.time) : null}
																	/>
																</Col>
																<Col span={12}>
																	<FoodModal
																		data={[
																			{ id: 1, name: 'Egg' },
																			{ id: 2, name: 'Papaya' },
																			{ id: 3, name: 'Milk' },
																			{ id: 4, name: 'Chicken' },
																		]}
																		value={item.foodId}
																		onChange={(value) => updateDietItem(weekInd, dayInd, itemInd, 'foodId', value)}
																	/>
																</Col>
																<Col span={5}>
																	<Input
																		placeholder="Quantity"
																		value={item.qty}
																		onChange={(e) => updateDietItem(weekInd, dayInd, itemInd, 'qty', e.target.value)}
																	/>
																</Col>
																<Col span={2}>
																	<Button
																		danger
																		icon={<DeleteOutlined />}
																		onClick={() => removeDietItem(weekInd, dayInd, itemInd)}
																	/>
																</Col>
															</Row>
														))}
														<Button
															style={{ width: '100%' }}
															type="dashed"
															onClick={() => addDietItem(weekInd, dayInd)}
															icon={<PlusOutlined />}>
															Add Item
														</Button>
													</Col>
												</Row>
											))}
										</React.Fragment>
									))}
								<Button type="dashed" style={{ marginTop: 16 }} onClick={addWeek} icon={<PlusOutlined />}>
									Add Week
								</Button>
							</>}

						<Row justify="end" gutter={16}>
							<Col>
								<Button type="default" onClick={onClose}>
									Cancel
								</Button>
							</Col>
							<Col>
								<Button type="primary" htmlType="submit">
									{editDietPlan ? 'Update' : 'Save'}
								</Button>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddDietPlan;
